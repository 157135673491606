window.VideoLibrary = () => {
	return {
		activeFilter: null,
		init() {
			// on load check if there is a category in the URL and set it as activeFilter
				this.activeFilter = this.getUrlParams();

		},
		updateUrlParams( categoryName ) {
			// when a filter button is clicked, set it as the category in the URL and update activeFilter
			const params = new URLSearchParams();
			params.set('category', categoryName);

			history.replaceState({}, "", `${window.location.pathname}?${params.toString()}`);
			this.activeFilter = categoryName;
		},
		getUrlParams() {
			// helper function to get the category from the URL
			const params = new URLSearchParams(window.location.search);

			return params.get('category');
		}
	};
};
